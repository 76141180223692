import { render, staticRenderFns } from "./activationResend.vue?vue&type=template&id=29ec9ab8"
import script from "./activationResend.vue?vue&type=script&lang=js"
export * from "./activationResend.vue?vue&type=script&lang=js"
import style0 from "./activationResend.vue?vue&type=style&index=0&id=29ec9ab8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports